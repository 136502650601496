import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import urljoin from 'url-join';
import { DiscussionEmbed } from 'disqus-react';
import Layout3 from '../components/layout3';
import SEO from '../components/seo';
import PostCard from '../components/post-card/post-card';
import PostDetails from '../components/post-details/post-details';
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from 'react-share';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoReddit,
} from 'react-icons/io';
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogPostComment,
  HonbunWrapper,
} from './templates.style';

import Sticky from 'react-stickynode';
import Cityset1 from "../components/citysetting/citytitle1"
import Cityset2 from "../components/citysetting/citytitle2"
import Styles from "./blog-post.module.css"
import Cesium1 from "../components/cesium/cesium1"
import Cesium2dis from "../components/cesium/cesium2dis"
import Refresh from "../components/refresh"

import Hanrei from "../containers/hanrei"
import CesiumDiv from "../pages/cesiumDiv";
import { styles } from 'styled-system';


import CesiumContainer from "../pages/cesiumContainer10ref"
import CesiumContainer2 from "../pages/cesiumContainer10ref_2"



const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};




const BlogPostTemplate = (props: any) => {

  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
  const [value2, setValue2] = useStateWithLocalStorage(
    'cityset2'
  );

  const post = props.data.markdownRemark;


  const kml_filenfd = post.frontmatter.kml_file.normalize( 'NFD' ) ;


  const { edges } = props.data.allMarkdownRemark;
  const title = post.frontmatter.title;
  const slug = post.fields.slug;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const shareUrl = urljoin(siteUrl, slug);
  const kmlsrc='https://empire-dev3.kashika.net/Cesium/Apps/kashika2/?sourceid='+post.frontmatter.no+'/福岡県糸島市_' + post.frontmatter.kml_file
  const kmldown='https://empire-dev3.kashika.net/kml/'+post.frontmatter.no+'/福岡県糸島市_' + post.frontmatter.kml_file
  const kmlsrc1='https://empire-dev3.kashika.net/Cesium/Apps/kashika2/?sourceid='+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file
  const kmldown1='https://empire-dev3.kashika.net/kml/'+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file


  const city1 = {value};
  const city1nfd = city1.value.normalize( 'NFD' ) ;

  const kml_kmz1='https://empire-dev3.kashika.net/kml/'+post.frontmatter.no+'/'+ city1nfd + '_' + kml_filenfd;
  
  const kml_pre='https://empire-dev3.kashika.net/kml/'+post.frontmatter.no+'/';
  const kml_kml_post='_' + kml_filenfd.substring(0, kml_filenfd.length-1) + 'l';
  const kml_kmz_post='_' + kml_filenfd;

  const kml_kml1='/kml/'+post.frontmatter.no+'/'+ city1nfd + '_' + kml_filenfd.substring(0, kml_filenfd.length-1) + 'l'

  const city2 = {value2};
  const city2nfd = city2.value2.normalize( 'NFD' ) ;

  const kml_kmz2='https://empire-dev3.kashika.net/kml/'+post.frontmatter.no+'/'+ city2nfd + '_' + kml_filenfd

  const kml_kml2='/kml/'+post.frontmatter.no+'/'+ city2nfd + '_' + kml_filenfd.substring(0, kml_filenfd.length-1) + 'l'

console.log(kml_kmz2);
console.log(kml_kml2);




  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
  };

  return (
    <Layout3>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
        <div>
        <div className={Styles.bar}>
          <div style={{display:"flex"}}>
            <div className={Styles.headerleft}>
              {/* <Cityset1 /> */}
              <a href={"/tags/sdgs-"+post.frontmatter.sdgs_no}><img style={{width:"100%",margin:"2px"}} src={"/sdgsicon/sdg_"+post.frontmatter.sdgs_no+".png"}/></a>
            </div>
            <div className={Styles.headerno}>
              {post.frontmatter.no}
            </div>
            <div className={Styles.headertitle}>
              {post.frontmatter.title}
            </div>
            <div className={Styles.headertitle2}>
              {post.frontmatter.height_name+"×"+post.frontmatter.color_name}
            </div>
            <div className={Styles.headerright}>
              <a className={Styles.button} href="/top">HOME</a>
            </div>
          </div>
        </div>
        </div>
      </Sticky>


      <div className={Styles.csstabgrid}>
      <div className={Styles.csstabgridinner}>
        <div className={Styles.csstabgridchild1}>
          <Link to={"/"+post.frontmatter.no+"/erabu"} className={Styles.dis2}>
          自分で地域を選択
          </Link>
        </div>
        <div className={Styles.csstabgridchild000}>
          <Link to={"/"+post.frontmatter.no+"/hikaku"} className={Styles.dis2}>
            2地域を比較
          </Link>
        </div>
      </div>
      <div className={Styles.csstabgridinner}>
        <div className={Styles.csstabgridchild3}>
          <Link to={"/"+post.frontmatter.no+"/tenkei"} className={Styles.dis2}>
            典型例と解説
          </Link>
        </div>
        <div className={Styles.csstabgridchild4}>
          <Link to={"/"+post.frontmatter.no+"/kokudo"} className={Styles.dis2}>
            国土像を俯瞰
          </Link>
        </div>
      </div>
    </div>


{/* 
    <div style={{display:"flex"}}>
      <div style={{width:"50%"}} className={Styles.panel}>
        <div>
          <div className={Styles.cesiumcontainer}>
            <div>
              <Cityset1 />
            </div>
            <CesiumContainer
              dir={kml_kml}
              downdir={kml_kmz}
              // dir="/kml/sample.kml"
              width="100%"
              height="700px"
              no={post.frontmatter.no}
            />
          </div>
        </div>
      </div>
<div style={{width:"50%"}} className={Styles.panel}>
        <div>
          <div className={Styles.cesiumcontainer}>
            <div>
              <Cityset2 />
            </div>
            <CesiumContainer
              dir={kml_kml2}
              downdir={kml_kmz2}
              // dir="/kml/sample.kml"
              width="100%"
              height="700px"
              no={post.frontmatter.no}
            />
          </div>
        </div>
      </div>

    </div> */}






      <div className={Styles.cesium2dis}>







      <div className={Styles.cssgrid}>


      <div className={Styles.panel}>
        <div>
          <div>
            </div>

            <div className={Styles.cssgridchild}>
        <div>
          <Cityset1 />
        </div>

            <CesiumContainer
              kml_kml={kml_kml1}
              kml_kmz={kml_kmz1}
              // dir="/kml/sample.kml"
              width="50vw"
              height="60vh"
              no={post.frontmatter.no}
              full_name="Full"
              tofull_url={"/"+post.frontmatter.no+"/erabuf"}
              full_url={"/"+post.frontmatter.no+"/erabuf"}
            />
            </div>
        </div>
      </div>


      <div className={Styles.panel}>
        <div>
          <div>
            </div>

            <div className={Styles.cssgridchild}>
        <div>
          <Cityset2 />
        </div>

            <CesiumContainer2
              kml_kml={kml_kml2}
              kml_kmz={kml_kmz2}
              // dir="/kml/sample.kml"
              // kml_pre={kml_pre}
              // kml_kml_post={kml_kml_post}
              // kml_kmz_post={kml_kmz_post}
              width="50vw"
              height="60vh"
              no={post.frontmatter.no}
              full_name="Full"
              tofull_url={"/"+post.frontmatter.no+"/erabuf"}
              full_url={"/"+post.frontmatter.no+"/erabuf"}
            />
            </div>
        </div>
      </div>



      {/* <Cesium2dis
        no={post.frontmatter.no}
        kml_file={post.frontmatter.kml_file}
      /> */}
      </div>



</div>


<details>
          <summary className={Styles.syousaiheader}>
            <h2>データの詳細情報を確認する</h2>
          </summary>

<div className={Styles.csssyousaigridinner}>
  <div className={Styles.csssyousaigridchild000}>
    <div className={Styles.table}>
      <div>
        <h2>高さ</h2>
        <table style={{width:"100%"}}>
          <thead>
            <tr>
              <th className={Styles.row1}></th>
              <th className={Styles.row2}>解説</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>名称</td>
              <td>{post.frontmatter.height_name}</td>
              </tr>
              <tr>
              <td>年次</td>
              <td>{post.frontmatter.year_display}</td>
              </tr>
              <tr>
              <td>出典</td>
              <td>{post.frontmatter.height_origin}</td>
              </tr>
              <tr>
              <td>定義</td>
              <td>{post.frontmatter.height_origin_note}</td>
              </tr>
              <tr>
              <td>詳細</td>
              <td>
                <a href={post.frontmatter.height_origin_url}>{post.frontmatter.height_origin_url_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url2}>{post.frontmatter.height_origin_url2_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url3}>{post.frontmatter.height_origin_url3_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url4}>{post.frontmatter.height_origin_url4_name}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>



  <div className={Styles.csssyousaigridchild000}>
    <div className={Styles.tablediv}>
      <div className={Styles.table}>
       <div>
          <h2>色</h2>
          <table>
          <thead>
          <tr>
          <th className={Styles.row1}></th>
          <th className={Styles.row2}>解説</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>名称</td>
          <td>{post.frontmatter.color_name}</td>
          </tr>
          <tr>
          <td>年次</td>
          <td>{post.frontmatter.year_display}</td>
          </tr>
          <tr>
          <td>出典</td>
          <td>{post.frontmatter.color_origin}</td>
          </tr>
          <tr>
          <td>定義</td>
          <td>{post.frontmatter.color_origin_note}</td>
          </tr>
          <tr>
          <td>詳細</td>
          <td>
            <a href={post.frontmatter.color_origin_url}>{post.frontmatter.color_origin_url_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url2}>{post.frontmatter.color_origin_url2_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url3}>{post.frontmatter.color_origin_url3_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url4}>{post.frontmatter.color_origin_url4_name}</a>
          </td>
          </tr>
          </tbody>
          </table>
        </div>
       </div>
      </div>
    </div>
  </div>

</details>


<details>
  <summary className={Styles.syousaiheader}>
      <h2>関連するデータを調べる</h2>
  </summary>

  <div className={Styles.csssyousaigridinner}>
    <div className={Styles.csssyousaigridchild000}>
      <div>
        <h2>
          タグ
        </h2>
      </div>
      <div>
        {post.frontmatter.tags == null ? null : (
        <div className={Styles.taglinks}>
          {post.frontmatter.tags.map((tag: string, index: number) => (
          <p>
            <Link
            key={index}
            to={`/tags/${_.kebabCase(tag)}/`}
            className={Styles.tag}
            >
              {`${tag}`}
            </Link>
          </p>
          ))}
        </div>
        )}
      </div>
    </div>

    <div className={Styles.csssyousaigridchild000}>
      <div>
        <h2>
          関連ページ
        </h2>
      </div>
      <div style={{display:"flex"}}>
        <div style={{margin:"0% 5% 0% 5%"}}>
            {edges.length !== 0 && (
          <div>
            {edges.map(({ node }: any) => (
            <div key={node.fields.slug}>
              <PostCard
                title={node.frontmatter.title || node.fields.slug}
                url={node.fields.slug}
                tags={node.frontmatter.tags}
                sdgs_no={node.frontmatter.sdgs_no}
                height_name={node.frontmatter.height_name}
                color_name={node.frontmatter.color_name}
              />
            </div>
            ))}
          </div>
          )}
        </div>
      </div>
    </div>
  </div>

</details>


    </Layout3>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlugHikaku($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fileAbsolutePath
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
        height_origin
        height_origin_note
        height_origin_url_name
        height_origin_url
        height_origin_url2_name
        height_origin_url2
        height_origin_url3_name
        height_origin_url3
        height_origin_url4_name
        height_origin_url4
        color_name
        color_origin
        color_origin_note
        color_origin_url_name
        color_origin_url
        color_origin_url2_name
        color_origin_url2
        color_origin_url3_name
        color_origin_url3
        color_origin_url4_name
        color_origin_url4
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
        hanrei1_name
        hanrei1_color
        hanrei2_name
        hanrei2_color
        hanrei3_name
        hanrei3_color
        hanrei4_name
        hanrei4_color
        height_ratio
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
            height_origin
            height_origin_note
            height_origin_url_name
            height_origin_url
            height_origin_url2_name
            height_origin_url2
            height_origin_url3_name
            height_origin_url3
            height_origin_url4_name
            height_origin_url4
            color_name
            color_origin
            color_origin_note
            color_origin_url_name
            color_origin_url
            color_origin_url2_name
            color_origin_url2
            color_origin_url3_name
            color_origin_url3
            color_origin_url4_name
            color_origin_url4
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
            hanrei1_name
            hanrei1_color
            hanrei2_name
            hanrei2_color
            hanrei3_name
            hanrei3_color
            hanrei4_name
            hanrei4_color
            height_ratio
          }
        }
      }
    }
  }
`;

